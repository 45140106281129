import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Modal } from 'react-bootstrap'
import Input from "./Input"
import Strings from '../assets/strings/Strings.json'
import { MAX_PROCEED_LENGTH, STATUS_200, STATUS_400 } from '../utils/constants'
import { _vendorListPaymentCard, resetResponse } from "../actions/VendorAction";

const GenLabel = (props) => {
  const dispatch = useDispatch();

  const showModal = props.showModal;
  const handleCloseGenLabelModal = props.handleCloseGenLabelModal;
  const destAddress = props.destAddress;
  const labelGenInfo = props.labelGenInfo;
  const generateLabel = props.generateLabel;

  const [passcode, setPasscode] = useState("");
  const [passcodeError, setPasscodeError] = useState("");
  const [confirmModal, setConfirmModalShow] = useState(false);
  const [cardDetails, setCardDetails] = useState("");

  // list payment card API call
  const listPaymentCard = () => dispatch(_vendorListPaymentCard());
  const listPaymentCardResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorListPaymentCardResponse);
  const platFormFee = parseFloat(labelGenInfo?.platforCharge) + parseFloat(labelGenInfo?.convCharge);

  useEffect(() => {
    listPaymentCard();
  }, [])

  useEffect(() => {
    if (listPaymentCardResponse && listPaymentCardResponse.apiStatus) {
      if (
        listPaymentCardResponse.apiErrorCode == STATUS_200 &&
        listPaymentCardResponse.apiResponse.cardInfo
      ) {
        setCardDetails(listPaymentCardResponse.apiResponse.cardInfo);
      } else if (listPaymentCardResponse.apiErrorCode === STATUS_400) {
        setCardDetails("")
      }
      resetResponse(dispatch, "listPaymentCard");
    }
  }, [listPaymentCardResponse]);

  const handleChange = (event) => {
    setPasscode(event.target.value)
  }
  //alert(JSON.stringify(labelGenInfo))
  const validateAndPay = () => {
    if (passcode === "PrOcEEd") {
      setPasscodeError("");
      setConfirmModalShow(true)
    }
    else
      setPasscodeError("Enter Correct Code");
  };

  const handleClose = () => {
    setPasscode("");
    handleCloseGenLabelModal();
  };

  return (

    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      centered
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Generate Label</Modal.Title>
      </Modal.Header>
      <Modal.Body className='generate-label-modal'>
        <Row>
          <Col lg="6">
            <Col className='address'>
              <p>Origin</p>
              <span className='d-block'>{labelGenInfo?.businessname}</span>
              <p> {labelGenInfo?.address && labelGenInfo.address}
                {labelGenInfo?.address2 && `, ${labelGenInfo.address2}`}
                {(labelGenInfo?.city || labelGenInfo?.state || labelGenInfo?.zip) && <br />}
                {labelGenInfo?.city && labelGenInfo.city}
                {labelGenInfo?.state && `, ${labelGenInfo.state}`}
                {labelGenInfo?.zip && `, ${labelGenInfo.zip}`}</p>
            </Col>
          </Col>
          <Col lg="6">
            <Col className='address'>
              <p>Destination</p>
              <span className='d-block'>{destAddress?.fullname}</span>
              <p>    {destAddress?.address1 && destAddress.address1}
                {destAddress?.address2 && `, ${destAddress.address2}`}
                {(destAddress?.city || destAddress?.statename || destAddress?.zip) && <br />}
                {destAddress?.city && destAddress.city}
                {destAddress?.statename && `, ${destAddress.statename}`}
                {destAddress?.zip && `, ${destAddress.zip}`}</p>
            </Col>
          </Col>
        </Row>
        <Col className="package-details">
          <h3>Package Details </h3>
          <Row>
            <Col md="3" className="package-info">
              <label className='d-block'>Weight</label>
              <span>{labelGenInfo?.weight.value} {" "}{labelGenInfo?.weight.unit}</span>
            </Col>
            <Col md="3" className="package-info">
              <label className='d-block'>Length</label>
              <span>{labelGenInfo?.dimensions.length} {" "}{labelGenInfo?.dimensions.unit}</span>
            </Col>
            <Col md="3" className="package-info">
              <label className='d-block'>Width</label>
              <span>{labelGenInfo?.dimensions.width} {" "}{labelGenInfo?.dimensions.unit}</span>
            </Col>
            <Col md="3" className="package-info">
              <label className='d-block'>Height</label>
              <span>{labelGenInfo?.dimensions.height} {" "}{labelGenInfo?.dimensions.unit}</span>
            </Col>
            <Col md="6" lg="3" className="package-info">
              <label className='d-block'>Carrier</label>
              <span>{labelGenInfo?.carrier_name}</span>
            </Col>
            <Col md="6" lg="3" className="package-info">
              <label className='d-block'>Package Type</label>
              <span>{labelGenInfo?.packageName}</span>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="package-fee">
              <div className="d-flex justify-content-between">
                <p>{Strings.SetupShipent.payment.ShippingLabeltxt}: </p><span>${labelGenInfo?.rate}</span>
              </div>
              <div className="d-flex justify-content-between">
                <p>{Strings.SetupShipent.payment.ServiceFeetxt}: </p><span>${ parseFloat(platFormFee).toFixed(2)}</span>
              </div>
              {/* <div className="d-flex justify-content-between">
                <p>Convenience charges: </p><span>${labelGenInfo?.convCharge}</span>
              </div> */}
              <div className="d-flex justify-content-between">
                <p>{Strings.SetupShipent.payment.PaymentProcessingtxt}: </p><span>${labelGenInfo?.stripeCharges}</span>
              </div>
              <div className='total-fee d-flex justify-content-between'>
                <p>{Strings.SetupShipent.payment.Total}: </p><span>${labelGenInfo?.actualCharge}</span>
              </div>
            </Col>
          </Row>
          {(cardDetails && cardDetails !== "") &&(
          <Row>     
          <Col lg="6" >             
              <div className='mt-3'>
                <h3>Paying With Card</h3>                    
                <div className="saved-cards">    
                <p className='card-number'>
                    XXXX XXXX XXXX {cardDetails?.last_four}
                  </p> 
                  
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">
                      Expires: {cardDetails?.exp_month}/
                      {cardDetails?.exp_year}
                    </p>
                  </div>
                </div>
                </div>
              
            </Col>
            <Col lg="6" className="d-flex align-items-end package-alert">
            <p>{Strings.SetupShipent.payment.cardEditInfo}
            </p>
          </Col>
          </Row>)}
        </Col>
        <Row>
          <Col lg="6" className="verify">
            <p>To proceed with your selection, type <span>PrOcEEd </span>
              in the text box and click Pay Now</p>
            <Input
              placeholder="Type PrOcEEd"
              name="verificationtxt"
              id="verificationtxt"
              value={passcode}
              maxLength={MAX_PROCEED_LENGTH}
              onChange={handleChange}
              errorMessage={passcodeError}
            />
          </Col>
          <Col lg="6" className="d-flex align-items-end package-alert">
            <p>Please confirm the package dimensions, as the generated label cannot be canceled, and the amount is non-refundable.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      {/* Void Modal */}
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModalShow(false)}
        backdrop="static"
        centered
        className='label-confirm'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h5>{Strings.SetupShipent.paymentConfirm.Alert}</h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-primary" onClick={() => setConfirmModalShow(false)}>  {Strings.SetupShipent.paymentConfirm.CanBtn}</Button>
          <Button variant="primary" onClick={() => generateLabel(labelGenInfo?.actualCharge)}>
            {Strings.SetupShipent.paymentConfirm.Btn}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={validateAndPay}>Pay Now</Button>
      </Modal.Footer>
    </Modal>

  )
};

export default GenLabel;
