/*global google*/
/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import {
  Col,
  Row,
  Modal,
  Accordion,
  Card,
  useAccordionButton,
  Dropdown,
  Button,
  Form,
} from "react-bootstrap";
import InnerLayout from "../components/InnerLayout";
import Strings from "../assets/strings/Strings.json";
import mapPointer from "../assets/img/map-pointer.svg";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/styles/HeatMap.scss";
import { _heatMap, _vendorZctaData, _vendorFeedBack, _vendorCategoryList, _SalesVsoffers, _timevsWeekDaySales, resetResponse } from "../actions/VendorAction";
import * as constants from "../utils/constants";
import * as alerts from '../utils/Messages'
import BarChart from "../components/BarChart";
import BarChartVertical from "../components/BarChartVertical";
import HeatmapChart from "../components/HeatmapChart"
import { Link, json, useLocation } from "react-router-dom";
import SearchInput from "../components/SearchInput";
import Arrow from "../assets/img/icons/Arrow";
import Filter from "../assets/img/icons/Filter";
import { currencyFormat } from '../helpers/validationHelper';
import Input from "../components/Input";
import axios from "axios";
import { STATUS_0, STATUS_1, STATUS_2, STATUS_3 } from "../utils/constants";
import InfoHeatmap from "../assets/img/icons/InfoHeatmap";
import ReactDOM from 'react-dom';
import ArrowAccordion from "../assets/img/icons/ArrowAccordion";
import CustSelect from "../components/Select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../components/Loader'

const HeatMap = () => {

  const intitalZctaFilterValue = {
    ageFrom: '',
    ageTo: '',
    agerange: [],
    capitalIncomeFrom: '',
    capitalIncomeTo: '',
    gender: [],
    houseHoldsFrom: '',
    houseHoldsTo: '',
    houseHoldsIncomeFrom: '',
    houseHoldsIncomeTo: '',
    medianHouseHoldFrom: '',
    medianHouseHoldTo: '',
    populationDensityFrom: '',
    populationDensityTo: '',
    raceAndEthinicity: [],
    rangeFrom: '',
    rangeTo: '',
    totalPopulationFrom: '',
    totalPopulationTo: '',
    zipCode: ''
  };
  const intitalZctaFilterError = {
    ageFrom: '',
    ageTo: '',
    capitalIncomeFrom: '',
    capitalIncomeTo: '',
    houseHoldsFrom: '',
    houseHoldsTo: '',
    houseHoldsIncomeFrom: '',
    houseHoldsIncomeTo: '',
    medianHouseHoldFrom: '',
    medianHouseHoldTo: '',
    populationDensityFrom: '',
    populationDensityTo: '',
    rangeFrom: '',
    rangeTo: '',
    totalPopulationFrom: '',
    totalPopulationTo: '',
  };

  const currentDate = new Date();
  const [weekTimeStartDate, setWeekTimeStartDate] = useState(new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000));
  const [weekTimeEndDate, setWeekTimeEndDate] = useState(currentDate);
  const [showNextWeekButton, setShowNextWeekButton] = useState(false);

  const mapRef = useRef(null);
  const map = useRef(null);
  const heatmap = useRef(null);
  const [heatmapData, setHeatmapData] = useState([]);
  const [latLong, setLatLong] = useState();
  const [load, setLoad] = useState(true);
  const [zCTALoad, setZCTALoad] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(true);
  const [fliterData, setFilterData] = useState(intitalZctaFilterValue);
  const [fliterDataError, setFilterDataError] = useState(intitalZctaFilterError);
  const [zctaData, setZctaData] = useState();
  const [zctaMaleFemale, setZctaMaleFemale] = useState({ male: [], female: [] });
  const [viewDetailsClicked, setViewDetailsClicked] = useState(false);
  const [pointIntrestData, setPointIntrestData] = useState({
    airports: [],
    trains: [],
    schools: [],
  });
  const [zctaSearchResults, setZctaSearchResults] = useState();
  const [zctaSearchClicked, setzctaSearchClicked] = useState(false);
  const [searchZipcodeValue, setSearchZipcodeValue] = useState('');
  const [searchZipcodeValueError, setSearchZipcodeValueError] = useState('');
  const [filterType, setFilterType] = useState(STATUS_0);
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState({ value: STATUS_1, label: "" });
  const [categoryValueHeatmapChart, setCategoryValueHeatmapChart] = useState({ value: STATUS_1, label: "" });
  const [subcategoryNames, setSubcategoryNames] = useState([]);
  const [sales, setSales] = useState([]);
  const [offers, setOffers] = useState([]);
  const [heatmapChartData, setHeatmapChartData] = useState([]);
  const [minPriceTimevsWeekDaySales, setMinPriceTimevsWeekDaySales] = useState();
  const [maxPriceTimevsWeekDaySales, setMaxPriceTimevsWeekDaySales] = useState();
  const [avgPriceTimevsWeekDaySales, setAvgPriceTimevsWeekDaySales] = useState();
  const location = useLocation()
  const dispatch = useDispatch();
  const heatMap = () => dispatch(_heatMap());
  const heatMapResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.heatMapinfo
  );
  const vendorZctaData = (data) => dispatch(_vendorZctaData(data));

  const vendorZctaDataResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.zctaData
  );

  // category list start
  const categoryList = () => dispatch(_vendorCategoryList());
  const categoryListApiResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorCategoryListResponse
  );

  const SalesVsoffers = (data) => dispatch(_SalesVsoffers(data));
  const SalesVsoffersResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.SalesVsoffersResponse
  );

  const timevsWeekDaySales = (data) => dispatch(_timevsWeekDaySales(data));
  const timevsWeekDaySalesResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.timevsWeekDaySalesResponse
  );

  // Function to compute a value for color coding
  const computeValue = useCallback((item) => {
    return 500;
  }, []);


  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)",
  ];

  const dayNameMapping = {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun'
  };

  // Function to get a color based on the computed value
  const getColorForValue = useCallback((value) => {
    if (value >= 1000) {
      return "red"; // Red
    } else if (value >= 100) {
      return "yellow"; // Yellow
    } else {
      return "green"; // Green
    }
  }, []);


  const getBrowserTimezoneOffsetInMinutes = () => {
    const offset = new Date().getTimezoneOffset();
    return -offset;
  };

  const MedianLabel = constants.MedianLabel
  const RaceLabel = constants.RaceLabel
  const AgeLabel = constants.AgeLabel
  const HouseholdLabel = constants.HouseholdLabel
  const HouseholdIncomeLabel = constants.HouseholdIncomeLabel

  useEffect(() => {
    const genderValues = fliterData.gender
    if (fliterData.zipCode && viewDetailsClicked) {
      vendorZctaData({
        ageFrom: fliterData.ageFrom,
        ageTo: fliterData.ageTo,
        agerange: fliterData.agerange,
        capitalIncomeFrom: fliterData.capitalIncomeFrom,
        capitalIncomeTo: fliterData.capitalIncomeTo,
        gender: genderValues?.length >= constants.STATUS_2 || genderValues?.length === constants.STATUS_0 ? null : genderValues[0],
        houseHoldsFrom: fliterData.houseHoldsFrom,
        houseHoldsTo: fliterData.houseHoldsTo,
        houseHoldsIncomeFrom: fliterData.houseHoldsIncomeFrom,
        houseHoldsIncomeTo: fliterData.houseHoldsIncomeTo,
        medianHouseHoldFrom: fliterData.medianHouseHoldFrom,
        medianHouseHoldTo: fliterData.medianHouseHoldTo,
        populationDensityFrom: fliterData.populationDensityFrom,
        populationDensityTo: fliterData.populationDensityTo,
        raceAndEthinicity: fliterData.raceAndEthinicity,
        rangeFrom: fliterData.rangeFrom,
        rangeTo: fliterData.rangeTo,
        totalPopulationFrom: fliterData.totalPopulationFrom,
        totalPopulationTo: fliterData.totalPopulationTo,
        zipCode: fliterData.zipCode
      })
      setZCTALoad(true)
      setSearchZipcodeValue(fliterData.zipCode)
      setSearchZipcodeValueError('')
      setWeekTimeStartDate(new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000));
      setWeekTimeEndDate(currentDate);
    }
  }, [viewDetailsClicked]);

  useEffect(() => {
    categoryList();
    if (scriptLoaded) {
      setScriptLoaded(false);
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=initMap&libraries=visualization&v=weekly`;
      script.async = true;

      script.onerror = (error) => {
        console.error("Error loading Google Maps API script:", error);
      };

      document.head.appendChild(script);
      setTimeout(() => {
        heatMap();
      }, 1500);
      setLoad(true);
      // Remove the script from the DOM when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    }

  }, []);




  useEffect(() => {

    if(!fliterData.zipCode){
      SalesVsoffers({
        "zipCode": null,
        "categoryId": categoryValue?.value
      })
    }
    else if (categoryValue && fliterData.zipCode) {
      SalesVsoffers({
        "zipCode": fliterData.zipCode,
        "categoryId": categoryValue?.value
      })
    }

  }, [categoryValue, fliterData.zipCode]);


  useEffect(() => {
    if (categoryValue && fliterData.zipCode) {
      timevsWeekDaySales({
        "zipCode": fliterData.zipCode,
        "categoryId": categoryValueHeatmapChart?.value,
        "fromDate": weekTimeStartDate,
        "toDate": weekTimeEndDate,
        "timeOffset": getBrowserTimezoneOffsetInMinutes()
      })
    }
  }, [categoryValueHeatmapChart, fliterData.zipCode, weekTimeStartDate]);


  // function to get the lat and long of the user entered zipcode
  const handleGetLocation = (zipcode) => {
    setLoad(true)
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.results.length > 0) {
          const result = response.data.results[0].geometry.location;
          initMap(result.lat, result.lng, constants.STATUS_0, zipcode)
        } else {
          setLoad(false)
          initMap(37.775, -122.434)
        }
      })
      .catch((err) => {
        console.error("Error fetching address:", err);
      });

  };

  const similarZipcodesearch = (zipCode) => {
    const genderValues = fliterData.gender
    if (zipCode) {
      vendorZctaData({
        ageFrom: fliterData.ageFrom,
        ageTo: fliterData.ageTo,
        agerange: fliterData.agerange,
        capitalIncomeFrom: fliterData.capitalIncomeFrom,
        capitalIncomeTo: fliterData.capitalIncomeTo,
        gender: genderValues?.length >= constants.STATUS_2 || genderValues?.length === constants.STATUS_0 ? null : genderValues[0],
        houseHoldsFrom: fliterData.houseHoldsFrom,
        houseHoldsTo: fliterData.houseHoldsTo,
        houseHoldsIncomeFrom: fliterData.houseHoldsIncomeFrom,
        houseHoldsIncomeTo: fliterData.houseHoldsIncomeTo,
        medianHouseHoldFrom: fliterData.medianHouseHoldFrom,
        medianHouseHoldTo: fliterData.medianHouseHoldTo,
        populationDensityFrom: fliterData.populationDensityFrom,
        populationDensityTo: fliterData.populationDensityTo,
        raceAndEthinicity: fliterData.raceAndEthinicity,
        rangeFrom: fliterData.rangeFrom,
        rangeTo: fliterData.rangeTo,
        totalPopulationFrom: fliterData.totalPopulationFrom,
        totalPopulationTo: fliterData.totalPopulationTo,
        zipCode: zipCode
      })
      setZCTALoad(true)
    }
  };

  const convertTo12Hour = (time24) => {
    const period = time24 >= 12 ? 'PM' : 'AM';
    const adjustedHours = time24 % 12 || 12;
    return `${adjustedHours} ${period}`;
  };

  const markerInfoStyle = (zipCode) => {
    return `
      <div class="map-point-dtls">
        <h6>Selected Zip Code</h6>
        <span>${zipCode}<br>
        <button id="view-details-btn">View Details</button>
      </div>
    `;
  };

  const initMap = (mapLat, mapLong, type, zipCode) => {
    setzctaSearchClicked(false);
    setLoad(false);
    if (!mapRef.current) {
      console.error("Map reference is null");
      return;
    }

    map.current = new window.google.maps.Map(mapRef.current, {
      center: { lat: mapLat, lng: mapLong },
      zoom: 4,
      scaleControl: true,
      mapTypeControl: true,
      minZoom: 3,
    });

    if (
      window.google &&
      window.google.maps &&
      window.google.maps.visualization &&
      window.google.maps.visualization.HeatmapLayer
    ) {
      heatmap.current = new window.google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        map: map.current,
        opacity: 1,
        gradient: gradient,
      });
    }
    // createLegend();

    const markerIcon = {
      url: { mapPointer },
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 40),
    };

    let marker = null;
    const infoWindow = new window.google.maps.InfoWindow({
      position: { lat: mapLat, lng: mapLong },
    });

    const createMarker = (position) => {
      if (marker) {
        marker.setMap(null); // Remove the old marker from the map
      }
      marker = new window.google.maps.Marker({
        position,
        map: map.current,
        icon: markerIcon,
        draggable: false,
      });
    };

    if (type === constants.STATUS_0) {
      setLatLong({ lat: mapLat, lng: mapLong });
      infoWindow.close();
      map.current.setZoom(10);
      createMarker({ lat: mapLat, lng: mapLong });
      infoWindow.open(map.current, marker);
      infoWindow.setContent(markerInfoStyle(zipCode));
      marker.setMap(map.current);
      // handleGetLocationAddress(mapLat, mapLong, infoWindow, marker, markerIcon);
    }

    map.current.addListener("click", (mapsMouseEvent) => {
      const latLng = mapsMouseEvent.latLng;
      setLatLong(latLng);
      infoWindow.close();
      createMarker(latLng);
      const lat = latLng.lat();
      const lng = latLng.lng();
      handleGetLocationAddress(lat, lng, infoWindow, marker, mapsMouseEvent, markerIcon);
    });

    google.maps.event.addListener(infoWindow, 'domready', function () {
      const viewDetailsButton = document.getElementById('view-details-btn');
      if (viewDetailsButton) {
        viewDetailsButton.addEventListener('click', () => setViewDetailsClicked(true));
      }
    });
  };


  const handleGetLocationAddress = (
    lat,
    long,
    infoWindow,
    marker,
  ) => {
    // Replace 'YOUR_API_KEY' with your Google Maps API key
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.results.length > 0) {
          const result = response.data.results[0];
          const zipCode = result.address_components.find((component) =>
            component.types.includes("postal_code")
          )?.long_name;
          const city = result.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name;
          if (zipCode) {
            infoWindow.open(map.current, marker);
            setFilterData((fliterData) => ({
              ...fliterData,
              zipCode: zipCode,
            }));
            infoWindow.setContent(markerInfoStyle(zipCode));
            marker.setMap(map.current);
          }
        } else {
          console.log("Invalid Zipcode");
        }
      })
      .catch((err) => {
        console.error("Error fetching address:", err);
      });
  };


  const handleRaceAndEthnicityFilterChanges = (e) => {

    const raceId = parseInt(e.target.id);
    const status = e.target.value;

    switch (raceId) {

      case 1:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 2:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 3:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 4:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 5:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 6:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 7:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      case 8:
        if (status === "on") {
          if (fliterData.raceAndEthinicity.indexOf(raceId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: [...prevData.raceAndEthinicity, raceId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              raceAndEthinicity: prevData.raceAndEthinicity.filter(race => race !== raceId),
            }));
          }
        }
        break;

      default:
        break;
    }
  };


  const handleAgeandSexFilterChanges = (e) => {

    const ageId = parseInt(e.target.id);
    const status = e.target.value;

    switch (ageId) {

      case 1:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 2:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 3:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 4:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 5:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 6:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 7:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 8:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      case 9:
        if (status === "on") {
          if (fliterData.agerange.indexOf(ageId) === -1) {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: [...prevData.agerange, ageId],
            }));
          }
          else {
            setFilterData((prevData) => ({
              ...prevData,
              agerange: prevData.agerange.filter(age => age !== ageId),
            }));
          }
        }
        break;

      default:
        break;
    }
  };


  const handleGenderFilterChanges = (e) => {
    const genderId = e.target.id;
    const status = e.target.value;

    if (genderId === 'M') {
      if (status === 'on') {
        if (fliterData.gender.indexOf(genderId) === -1) {
          setFilterData((prevData) => ({
            ...prevData,
            gender: [...prevData.gender, genderId],
          }));
        }
        else {
          setFilterData((prevData) => ({
            ...prevData,
            gender: prevData.gender.filter(age => age !== genderId),
          }));
        }
      }
    }

    if (genderId === 'F') {
      if (status === 'on') {
        if (fliterData.gender.indexOf(genderId) === -1) {
          setFilterData((prevData) => ({
            ...prevData,
            gender: [...prevData.gender, genderId],
          }));
        }
        else {
          setFilterData((prevData) => ({
            ...prevData,
            gender: prevData.gender.filter(age => age !== genderId),
          }));
        }
      }
    }
  };


  const filterRange = (e) => {
    const rangeName = e.target.id;
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setFilterData((prevData) => ({
        ...prevData,
        [rangeName]: value,
      }));
    }
  };


  const addRangeValidation = () => {
    let isValid = true;
    setFilterDataError('');

    if ((parseInt(fliterData.ageFrom) && parseInt(fliterData.ageTo) !== null) && (parseInt(fliterData.ageFrom) >= parseInt(fliterData.ageTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["ageFrom"]: Strings.HeatMap.FilterError,
      }));
    }

    if ((parseInt(fliterData.capitalIncomeFrom) && parseInt(fliterData.capitalIncomeTo) !== null) && (parseInt(fliterData.capitalIncomeFrom) >= parseInt(fliterData.capitalIncomeTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["capitalIncomeFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.houseHoldsFrom) && parseInt(fliterData.houseHoldsTo) !== null) && (parseInt(fliterData.houseHoldsFrom) >= parseInt(fliterData.houseHoldsTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["houseHoldsFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.houseHoldsIncomeFrom) && parseInt(fliterData.houseHoldsIncomeTo) !== null) && (parseInt(fliterData.houseHoldsIncomeFrom) >= parseInt(fliterData.houseHoldsIncomeTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["houseHoldsIncomeFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.medianHouseHoldFrom) && parseInt(fliterData.medianHouseHoldTo) !== null) && (parseInt(fliterData.medianHouseHoldFrom) >= parseInt(fliterData.medianHouseHoldTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["medianHouseHoldFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.populationDensityFrom) && parseInt(fliterData.populationDensityTo) !== null) && (parseInt(fliterData.populationDensityFrom) >= parseInt(fliterData.populationDensityTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["populationDensityFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.rangeFrom) && parseInt(fliterData.rangeTo) !== null) && (parseInt(fliterData.rangeFrom) >= parseInt(fliterData.rangeTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["rangeFrom"]: Strings.HeatMap.FilterError,
      }));
    }
    if ((parseInt(fliterData.totalPopulationFrom) && parseInt(fliterData.totalPopulationTo) !== null) && (parseInt(fliterData.totalPopulationFrom) >= parseInt(fliterData.totalPopulationTo))) {
      isValid = false;
      setFilterDataError((fliterDataError) => ({
        ...fliterDataError,
        ["totalPopulationFrom"]: Strings.HeatMap.FilterError,
      }));
    }

    return isValid;
  };


  //Search with filter values
  const searchWithFilter = () => {
    const genderValues = fliterData.gender
    let isValid = addRangeValidation();
    if (isValid && fliterData.zipCode) {
      vendorZctaData({
        ageFrom: parseInt(fliterData.ageFrom),
        ageTo: parseInt(fliterData.ageTo),
        agerange: fliterData.agerange,
        capitalIncomeFrom: parseInt(fliterData.capitalIncomeFrom),
        capitalIncomeTo: parseInt(fliterData.capitalIncomeTo),
        gender: genderValues?.length >= constants.STATUS_2 || genderValues?.length === constants.STATUS_0 ? null : genderValues[0],
        houseHoldsFrom: parseInt(fliterData.houseHoldsFrom),
        houseHoldsTo: parseInt(fliterData.houseHoldsTo),
        houseHoldsIncomeFrom: parseInt(fliterData.houseHoldsIncomeFrom),
        houseHoldsIncomeTo: parseInt(fliterData.houseHoldsIncomeTo),
        medianHouseHoldFrom: parseInt(fliterData.medianHouseHoldFrom),
        medianHouseHoldTo: parseInt(fliterData.medianHouseHoldTo),
        populationDensityFrom: parseInt(fliterData.populationDensityFrom),
        populationDensityTo: parseInt(fliterData.populationDensityTo),
        raceAndEthinicity: fliterData.raceAndEthinicity,
        rangeFrom: parseInt(fliterData.rangeFrom),
        rangeTo: parseInt(fliterData.rangeTo),
        totalPopulationFrom: parseInt(fliterData.totalPopulationFrom),
        totalPopulationTo: parseInt(fliterData.totalPopulationTo),
        zipCode: parseInt(fliterData.zipCode)
      })
      setZCTALoad(true)
      setFilterType(STATUS_0)
      setzctaSearchClicked(true)
    }
  }

  const clearRaceAndEthnicityFilter = () => {
    setFilterData((filterData) => ({
      ...filterData,
      rangeFrom: '',
      rangeTo: '',
      raceAndEthinicity: [],
    }));
  }
  const clearAgeAndSexFilter = () => {
    setFilterData((filterData) => ({
      ...filterData,
      ageFrom: '',
      ageTo: '',
      gender: [],
      agerange: []
    }));
  }
  const clearFilter = () => {
    setFilterData((filterData) => ({
      ...filterData,
      capitalIncomeFrom: '',
      capitalIncomeTo: '',
      houseHoldsIncomeFrom: '',
      houseHoldsIncomeTo: '',
      houseHoldsFrom: '',
      houseHoldsTo: '',
      medianHouseHoldFrom: '',
      medianHouseHoldTo: '',
      populationDensityFrom: '',
      populationDensityTo: '',
      totalPopulationFrom: '',
      totalPopulationTo: '',

    }));
  }


  //Hnadle  zipcode search
  const searchTextChange = (searchText) => {
    setSearchZipcodeValue(searchText)
    const genderValues = fliterData.gender
    if (searchText && searchText.length === constants.STATUS_5) {
      vendorZctaData({
        ageFrom: fliterData.ageFrom,
        ageTo: fliterData.ageTo,
        agerange: fliterData.agerange,
        capitalIncomeFrom: fliterData.capitalIncomeFrom,
        capitalIncomeTo: fliterData.capitalIncomeTo,
        gender: genderValues?.length >= 2 || genderValues?.length === 0 ? null : genderValues[0],
        houseHoldsFrom: fliterData.houseHoldsFrom,
        houseHoldsTo: fliterData.houseHoldsTo,
        houseHoldsIncomeFrom: fliterData.houseHoldsIncomeFrom,
        houseHoldsIncomeTo: fliterData.houseHoldsIncomeTo,
        medianHouseHoldFrom: fliterData.medianHouseHoldFrom,
        medianHouseHoldTo: fliterData.medianHouseHoldTo,
        populationDensityFrom: fliterData.populationDensityFrom,
        populationDensityTo: fliterData.populationDensityTo,
        raceAndEthinicity: fliterData.raceAndEthinicity,
        rangeFrom: fliterData.rangeFrom,
        rangeTo: fliterData.rangeTo,
        totalPopulationFrom: fliterData.totalPopulationFrom,
        totalPopulationTo: fliterData.totalPopulationTo,
        zipCode: searchText
      })
      setSearchZipcodeValueError('')
      setZCTALoad(true)
      setFilterData((fliterData) => ({
        ...fliterData,
        zipCode: searchText,
      }));
      handleGetLocation(searchText)
    }
    else if (searchText.length === constants.STATUS_0) {
      setSearchZipcodeValueError(Strings.HeatMap.ZipCodevalidationEmpty)
    }
    else {
      setSearchZipcodeValueError(Strings.HeatMap.InvalidZipcode)
    }
  }


  // handle category change
  const categoriesChange = (value) => {
    setCategoryValue(value);
  };

  const categoriesChangeHeatmapChart = (value) => {
    setCategoryValueHeatmapChart(value);
  };


  useEffect(() => {
    if (heatMapResponse && heatMapResponse.apiStatus) {
      setLoad(false);
      if (heatMapResponse.apiErrorCode === constants.STATUS_200) {
        let AD = [];
        let DA = [];
        heatMapResponse.apiResponse.map((item) => {
          // Use the memoized functions
          const value = computeValue(item);
          const color = getColorForValue(value);
          if (
            item.location &&
            item.location.coordinates &&
            Array.isArray(item.location.coordinates) &&
            item.location.coordinates.length >= 2
          ) {
            if (AD.length === 0) {
              AD.push({
                lat: item.location.coordinates[1],
                long: item.location.coordinates[0],
              });
              DA.push(
                new window.google.maps.LatLng(
                  item.location.coordinates[1],
                  item.location.coordinates[0]
                )
              );
            } else {
              const result = AD.find(
                ({ lat }) => lat === item.location.coordinates[1]
              );
              if (!result) {
                AD.push({
                  lat: item.location.coordinates[1],
                  long: item.location.coordinates[0],
                });
                DA.push(
                  new window.google.maps.LatLng(
                    item.location.coordinates[1],
                    item.location.coordinates[0]
                  )
                );
              }
            }
          }
        });
        setHeatmapData(DA);
      }
    }
  }, [heatMapResponse]);


  useEffect(() => {
    if (vendorZctaDataResponse && vendorZctaDataResponse.apiStatus) {
      setViewDetailsClicked(false);
      setZCTALoad(false)
      setzctaSearchClicked(false)
      setZctaSearchResults()
      if (vendorZctaDataResponse.apiErrorCode === constants.STATUS_200) {
        if (vendorZctaDataResponse.apiResponse.zctaData && vendorZctaDataResponse.apiResponse.zctaData.cityInfo && vendorZctaDataResponse.apiResponse.zctaData.cityInfo[0] && !zctaSearchClicked) {
          setZctaData(vendorZctaDataResponse.apiResponse.zctaData)
          window.scrollTo({
            top: 700,
            behavior: "smooth",
          });
          if (vendorZctaDataResponse.apiResponse.zctaData.ageAndSexInfoFemale[0] && vendorZctaDataResponse.apiResponse.zctaData.ageAndSexInfoMale[0]) {
            setZctaMaleFemale((zctaMaleFemale) => ({ ...zctaMaleFemale, male: vendorZctaDataResponse.apiResponse.zctaData.ageAndSexInfoMale[0], female: vendorZctaDataResponse.apiResponse.zctaData.ageAndSexInfoFemale[0] }))
          }
          if (vendorZctaDataResponse.apiResponse.zctaData.pointOfInterest[0]) {
            setPointIntrestData((pointIntrestData) => ({
              ...pointIntrestData,
              airports: vendorZctaDataResponse.apiResponse.zctaData.pointOfInterest[0]?.airports,
              schools: vendorZctaDataResponse.apiResponse.zctaData.pointOfInterest[0]?.schools,
              trains: vendorZctaDataResponse.apiResponse.zctaData.pointOfInterest[0]?.trains
            }));
          }
        } else if (vendorZctaDataResponse.apiResponse.zctaData && zctaSearchClicked && vendorZctaDataResponse.apiResponse.zctaData.zctaSearchResults) {
          setZctaSearchResults(vendorZctaDataResponse.apiResponse.zctaData.zctaSearchResults)
          setZctaData()
        } else {
          setZctaData()
          setZctaSearchResults()
        }
        resetResponse(dispatch, "zctaData");
      }
    }
  }, [vendorZctaDataResponse]);


  useEffect(() => {
    if (SalesVsoffersResponse && SalesVsoffersResponse.apiStatus) {
      if (SalesVsoffersResponse.apiErrorCode === constants.STATUS_200) {
        setSubcategoryNames(SalesVsoffersResponse?.apiResponse.map(item => item.subcategoryname));
        setSales(SalesVsoffersResponse?.apiResponse.map(item => item.sales));
        setOffers(SalesVsoffersResponse?.apiResponse.map(item => item.offers));
      }
    }
  }, [SalesVsoffersResponse]);

  useEffect(() => {
    const groupedData = {};
    if (timevsWeekDaySalesResponse && timevsWeekDaySalesResponse.apiStatus) {
      if (timevsWeekDaySalesResponse.apiErrorCode === constants.STATUS_200) {

        if (timevsWeekDaySalesResponse.apiResponse.rangeData) {
          setAvgPriceTimevsWeekDaySales(timevsWeekDaySalesResponse.apiResponse.rangeData?.avgSales)
          setMaxPriceTimevsWeekDaySales(timevsWeekDaySalesResponse.apiResponse.rangeData?.maxSales)
          setMinPriceTimevsWeekDaySales(timevsWeekDaySalesResponse.apiResponse.rangeData?.minSales)
        }

        timevsWeekDaySalesResponse.apiResponse.hourWiseSalesData?.forEach(item => {
          const dayAbbrev = dayNameMapping[item.purchaseDay];
          if (!groupedData[dayAbbrev]) {
            groupedData[dayAbbrev] = [];
          }
          groupedData[dayAbbrev].push({
            x: convertTo12Hour(item.purchaseHour),
            y: item.sales
          });
        });
        const result = Object.keys(groupedData).map(day => ({
          name: day,
          data: groupedData[day]
        }));
        setHeatmapChartData(result);
      }
    }
  }, [timevsWeekDaySalesResponse]);



  useEffect(() => {
    if (window.google && window.google.maps) {
      if (location?.state?.zipCode) {
        setFilterData((fliterData) => ({
          ...fliterData,
          zipCode: location?.state?.zipCode,
        }));
        handleGetLocation(location?.state?.zipCode)
      } else {
        initMap(37.775, -122.434)
      };
    } else if (heatmapData.length !== 0) {
      console.error("Google Maps API is not available");
    }
  }, [heatmapData]);


  // category listing response
  useEffect(() => {
    if (categoryListApiResponse && categoryListApiResponse.apiStatus) {
      if (categoryListApiResponse.apiErrorCode === constants.STATUS_200) {
        if (categoryListApiResponse.apiResponse.category_info) {
          let categoryArray = [];
          categoryListApiResponse.apiResponse.category_info.map((category) => {
            categoryArray.push({
              value: category.category_id,
              label: category.category_name,
            });
          });
          setCategories(categoryArray.filter(item => item.label !== "Coupons"));
          setCategoryValue(categoryArray[0])
          setCategoryValueHeatmapChart(categoryArray[0])
        }
      }
    }
  }, [categoryListApiResponse]);

  // const createLegend = () => {
  //   const legend = document.createElement('div');
  //   legend.className = 'legend-item';

  //   const legendLabel = document.createElement('div');
  //   legendLabel.className = 'legend-label';

  //   const infoHeatmapContainer = document.createElement('div');
  //   infoHeatmapContainer.className = 'info-heatmap-container';

  //   const outerContainer = document.createElement('div');
  //   outerContainer.className = 'outer-container';

  //   outerContainer.appendChild(infoHeatmapContainer);

  //   ReactDOM.render(<InfoHeatmap />, infoHeatmapContainer);

  //   legendLabel.appendChild(outerContainer);

  //   legend.appendChild(legendLabel);

  //   map.current.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
  // };

  const handleFilterType = (Type) => {
    setFilterType(Type)
  }
  const handleWeekTimeDataChange = (date) => {
    const currentDate = new Date();
    let endDate = date ? new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000) : null;

    // If the calculated end date exceeds the current date
    if (endDate > currentDate) {
      endDate = currentDate;
      const adjustedStartDate = new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000);
      setWeekTimeStartDate(adjustedStartDate);
      setWeekTimeEndDate(currentDate);
    } else {
      setWeekTimeStartDate(date);
      setWeekTimeEndDate(endDate);
    }

    // Check if the new start date exceeds the current date and hide the button if so
    const newStartDate = new Date(date.getTime() + 6 * 24 * 60 * 60 * 1000);
    if (newStartDate > currentDate) {
      setShowNextWeekButton(false);
    } else {
      setShowNextWeekButton(true);
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString();
  };

  const handleButtonClickPrevious = () => {
    if (weekTimeStartDate) {
      const newStartDate = new Date(weekTimeStartDate.getTime() - 6 * 24 * 60 * 60 * 1000);
      handleWeekTimeDataChange(newStartDate);
    }
  };

  const handleButtonClickNext = () => {
    if (weekTimeStartDate) {
      const newStartDate = new Date(weekTimeStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);
      handleWeekTimeDataChange(newStartDate);
    }
  };

  const DatePickerCustomInput = React.forwardRef(
    ({ value, onClick, className }, ref) => (
      <input
        readOnly
        className={className}
        onClick={onClick}
        value={
          weekTimeStartDate && weekTimeEndDate
            ? `From: ${formatDate(weekTimeStartDate)} To: ${formatDate(weekTimeEndDate)}`
            : 'Select Date'
        }
        ref={ref}
      />
    ),
  );


  return (
    <InnerLayout onClick={() => handleFilterType(STATUS_0)}>
      <Col className="action-wrap pb-3">
        <Row>
          <Col lg="6">
            <h1>{Strings.HeatMap.Title}</h1>
          </Col>
          <Col lg="12" className="heatmap-dtl d-flex">
            <p>{Strings.HeatMap.Info}</p>
            <p>
              {Strings.HeatMap.Info1}
              <a
                href="https://nearbuyscom.zendesk.com/hc/en-us/articles/20743057669271-City-Information-for-Broadcast-Opportunities"
                target="_blank"
              >
                {" "}
                {Strings.HeatMap.Link}
              </a>
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="heatmap">
        {heatmapData && <div ref={mapRef} className="heatmap-map"></div>}

        {heatmapData && (
          <div className="map-legend d-flex pt-3 justify-content-end">
            <div className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: "red" }}
              ></div>
              <div className="legend-label">High</div>
            </div>
            <div className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: "#0002d0" }}
              ></div>
              <div className="legend-label">Mid</div>
            </div>
            <div className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: "#00a4ff" }}
              ></div>
              <div className="legend-label">Low</div>
            </div>
          </div>
        )}

        {load && <Loader/>}
      </Col>
      <Col>
        <Col className="data-analysis-main">
          <Card>
            <Card.Header>
              <Row>
                <Col lg="12" className="analysis-data-wrap">
                  <Row>
                    <Col lg="3" className="analysis-data">
                      <h5>{Strings.HeatMap.LabelHeading}</h5>
                    </Col>
                    <Col lg="9">
                      <Row>
                        <Col className="analysis-data justify-content-lg-end">
                          ZIP CODE: <span style={{ marginLeft: '3px' }}> {zctaData?.cityInfo[0]?.name} </span>
                        </Col>
                        <Col className="analysis-data justify-content-lg-end">
                          CITY: <span style={{ marginLeft: '3px' }}> {zctaData?.cityInfo[0]?.city.split(';')[0]} </span>
                        </Col>
                        <Col className="analysis-data justify-content-lg-end">
                          COUNTY: <span style={{ marginLeft: '3px' }}> {zctaData?.cityInfo[0]?.country.split(';')[0]} </span>
                        </Col>
                        <Col className="analysis-data justify-content-lg-end">
                          STATE: {zctaData?.cityInfo[0]?.city && <span style={{ marginLeft: '5px' }}>{zctaData?.cityInfo[0]?.state.split(';')[0]} </span>}
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="analysis-main">
              {zCTALoad && <Loader/>}
              {/* <Modal
                show={zCTALoad}
                onHide={() => setZCTALoad(false)}
                centered
                backdrop="static"
                className="loader-pop"
              >
                <div className=" align-items-center justify-content-center d-flex">
                  <div
                    className="spinner-grow"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#F9C901",
                      marginRight: 10,
                    }}
                    role="status"
                  />
                  <div
                    className="spinner-grow"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#F9C901",
                      marginRight: 10,
                    }}
                    role="status"
                  />
                  <div
                    className="spinner-grow"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#F9C901",
                      marginRight: 10,
                    }}
                    role="status"
                  />
                  <div
                    className="spinner-grow"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#F9C901",
                      marginRight: 10,
                    }}
                    role="status"
                  />
                  <div
                    className="spinner-grow"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#F9C901",
                      marginRight: 10,
                    }}
                    role="status"
                  />
                </div>
              </Modal> */}
              {
                <>
                  <Row>
                    <Col md="12" lg="8" xl="9" className="d-flex left-filter-wrap">
                      <SearchInput onClick={searchTextChange} p_SearchKey={searchZipcodeValue} placeholder="Search ZIP Code" maxLength={constants.STATUS_5} errorMessage={searchZipcodeValueError} />
                      <Button onClick={() => handleFilterType((filterType === STATUS_0 || filterType === STATUS_2 || filterType === STATUS_3) ? STATUS_1 : STATUS_0)} variant="outline-primary" className={filterType === STATUS_1 ? "active ms-3" : "ms-3"}>
                        Race & Ethnicity
                        <Arrow />
                      </Button>
                      <Button onClick={() => handleFilterType((filterType === STATUS_0 || filterType === STATUS_1 || filterType === STATUS_3) ? STATUS_2 : STATUS_0)} variant="outline-primary" className={filterType === STATUS_2 ? "active ms-3" : "ms-3"}>
                        Age & Sex
                        <Arrow />
                      </Button>
                    </Col>
                    <Col md="12" lg="4" xl="3" className="text-lg-end pt-md-3 pt-lg-0">
                      <Button
                        onClick={() => handleFilterType((filterType === STATUS_0 || filterType === STATUS_1 || filterType === STATUS_2) ? STATUS_3 : STATUS_0)} variant="outline-primary" className={filterType === STATUS_3 ? "active-filter min-w-auto no-margin me-3" : "min-w-auto no-margin me-3"}>
                        <Filter />
                      </Button>
                      <Button variant="primary" size="sm" onClick={searchWithFilter}>
                        Search
                      </Button>
                    </Col>
                    {/* Race and Ethnicity */}
                    {filterType === STATUS_1 && (
                      <div className="heatmap-filter race-ethnicity">
                        <div className="header-filter d-flex justify-content-between">
                          <h5>Race and Ethnicity</h5>
                          <Link to="#" onClick={() => clearRaceAndEthnicityFilter()}>Clear all</Link>
                        </div>
                        <div>
                          <Form.Check
                            inline label="White"
                            name="White"
                            id={"1"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(1) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Black"
                            name="Black"
                            id={"2"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(2) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Native"
                            name="Native"
                            id={"3"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(3) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Asian"
                            name="Asian"
                            id={"4"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(4) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Islander"
                            name="Islander"
                            id={"5"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(5) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Other"
                            name="Other"
                            id={"6"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(6) !== -1 ? true : false}
                          />

                          <Form.Check
                            inline label="Two or More"
                            name="Two or More"
                            id={"7"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(7) !== -1 ? true : false}
                          />
                          <Form.Check
                            inline label="Hispanic"
                            name="Hispanic"
                            id={"8"}
                            onChange={handleRaceAndEthnicityFilterChanges}
                            checked={fliterData.raceAndEthinicity.indexOf(8) !== -1 ? true : false}
                          />

                        </div>
                        <Col lg="3" className="mt-3">
                          <Row>
                            <label className="mb-2">Range</label>
                            <Col>
                              <Input
                                id="rangeFrom"
                                name="from"
                                type="string"
                                placeholder="From"
                                onChange={filterRange}
                                value={fliterData.rangeFrom}

                              />
                            </Col>
                            <Col>
                              <Input
                                id="rangeTo"
                                name="to"
                                type="string"
                                placeholder="To"
                                onChange={filterRange}
                                value={fliterData.rangeTo}
                              />
                            </Col>
                            <span style={{ color: "red" }}>{fliterDataError.rangeFrom}</span>
                          </Row>
                        </Col>
                      </div>
                    )}
                    {/* Age and Sex */}
                    {filterType === STATUS_2 && (
                      <div className="heatmap-filter age-sex">
                        <div className="header-filter d-flex justify-content-between">
                          <h5>Age and Sex </h5>
                          <Link to="#" onClick={() => { clearAgeAndSexFilter() }}>Clear all</Link>
                        </div>
                        <Row>
                          <Col lg={4} className="border-end pe-5">
                            <h6 className="mb-3">Sex</h6>
                            <Form.Check
                              inline label="Male"
                              name="Male"
                              id={'M'}
                              onChange={handleGenderFilterChanges}
                              checked={fliterData.gender.indexOf('M') !== -1 ? true : false}
                            />
                            <Form.Check
                              inline label="Female"
                              name="Female"
                              id={'F'}
                              onChange={handleGenderFilterChanges}
                              checked={fliterData.gender.indexOf('F') !== -1 ? true : false}
                            />
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">Range</label>
                                <Col>
                                  <Input
                                    id="ageFrom"
                                    name="from"
                                    type="string"
                                    placeholder="From"
                                    maxLength={constants.STATUS_3}
                                    onChange={filterRange}
                                    value={fliterData.ageFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="ageTo"
                                    name="to"
                                    type="string"
                                    placeholder="To"
                                    maxLength={constants.STATUS_3}
                                    onChange={filterRange}
                                    value={fliterData.ageTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.ageFrom}</span>
                              </Row>
                            </Col>
                          </Col>
                          <Col className="ps-5">
                            <div>
                              <h6 className="mb-3">Age</h6>
                              <Form.Check
                                inline label="0-9 years"
                                name="0-9"
                                id={"1"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(1) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="10-19 years"
                                name="10-19"
                                id={"2"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(2) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="20-29 years"
                                name="20-29"
                                id={"3"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(3) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="30-39 years"
                                name="30-39"
                                id={"4"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(4) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="40-49 years"
                                name="40-49"
                                id={"5"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(5) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="50-59 years"
                                name="50-59"
                                id={"6"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(6) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="60-69 years"
                                name="60-69"
                                id={"7"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(7) !== -1 ? true : false}
                              />

                              <Form.Check
                                inline label="70+ years"
                                name="70+"
                                id={"8"}
                                onChange={handleAgeandSexFilterChanges}
                                checked={fliterData.agerange.indexOf(8) !== -1 ? true : false}
                              />

                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {/* Filter */}
                    {filterType === STATUS_3 && (
                      <div className="heatmap-filter filter-ic">
                        <div className="header-filter d-flex justify-content-between">
                          <h5>Filter</h5>
                          <Link to="#" onClick={() => { clearFilter() }}>Clear all</Link>
                        </div>
                        <Row>
                          <Col lg={4} className="pe-5">
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">
                                  Median House hold by Age
                                </label>
                                <Col>
                                  <Input
                                    id="medianHouseHoldFrom"
                                    name="from"
                                    type="string"
                                    maxLength={constants.STATUS_3}
                                    placeholder="From"
                                    onChange={filterRange}
                                    value={fliterData.medianHouseHoldFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="medianHouseHoldTo"
                                    name="to"
                                    type="string"
                                    maxLength={constants.STATUS_3}
                                    placeholder="To"
                                    onChange={filterRange}
                                    value={fliterData.medianHouseHoldTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.medianHouseHoldFrom}</span>
                              </Row>
                            </Col>
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">Population Density</label>
                                <Col>
                                  <Input
                                    id="populationDensityFrom"
                                    name="from"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="From"
                                    onChange={filterRange}
                                    value={fliterData.populationDensityFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="populationDensityTo"
                                    name="to"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="To"
                                    onChange={filterRange}
                                    value={fliterData.populationDensityTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.populationDensityFrom}</span>
                              </Row>
                            </Col>
                          </Col>
                          <Col lg={4} className="pe-5">
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">Per Capita Income ($)</label>
                                <Col>
                                  <Input
                                    id="capitalIncomeFrom"
                                    name="from"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="From"
                                    onChange={filterRange}
                                    value={fliterData.capitalIncomeFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="capitalIncomeTo"
                                    name="to"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="To"
                                    onChange={filterRange}
                                    value={fliterData.capitalIncomeTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.capitalIncomeFrom}</span>
                              </Row>
                            </Col>
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">Households</label>
                                <Col>
                                  <Input
                                    id="houseHoldsFrom"
                                    name="from"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="From"
                                    onChange={filterRange}
                                    value={fliterData.houseHoldsFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="houseHoldsTo"
                                    name="to"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="To"
                                    onChange={filterRange}
                                    value={fliterData.houseHoldsTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.houseHoldsFrom}</span>
                              </Row>
                            </Col>
                          </Col>
                          <Col lg={4} className="pe-5">
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">Total Population</label>
                                <Col>
                                  <Input
                                    id="totalPopulationFrom"
                                    name="from"
                                    type="string"
                                    placeholder="From"
                                    maxLength={constants.STATUS_10}
                                    onChange={filterRange}
                                    value={fliterData.totalPopulationFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="totalPopulationTo"
                                    name="to"
                                    type="string"
                                    placeholder="To"
                                    maxLength={constants.STATUS_10}
                                    onChange={filterRange}
                                    value={fliterData.totalPopulationTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.totalPopulationFrom}</span>
                              </Row>
                            </Col>
                            <Col lg="12">
                              <Row>
                                <label className="mb-2">
                                  Household Income Distribution ($)
                                </label>
                                <Col>
                                  <Input
                                    id="houseHoldsIncomeFrom"
                                    name="from"
                                    type="string"
                                    placeholder="From"
                                    maxLength={constants.STATUS_8}
                                    onChange={filterRange}
                                    value={fliterData.houseHoldsIncomeFrom}
                                  />
                                </Col>
                                <Col>
                                  <Input
                                    id="houseHoldsIncomeTo"
                                    name="to"
                                    type="string"
                                    maxLength={constants.STATUS_8}
                                    placeholder="To"
                                    onChange={filterRange}
                                    value={fliterData.houseHoldsIncomeTo}
                                  />
                                </Col>
                                <span style={{ color: "red" }}>{fliterDataError.houseHoldsIncomeFrom}</span>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Row>

                  <Row className="similar-code-main mt-4 d-flex align-items-center">
                    <Col xs="auto">
                      {fliterData?.zipCode && (zctaData?.similarZipCodes || zctaSearchResults) ? <h6>Similar Zip Codes:</h6> : ""}
                    </Col>
                    <Col className="d-flex similar-zip-wrap">
                      {zctaData && zctaData.similarZipCodes
                        .filter(item => item.name !== fliterData.zipCode)
                        .map((item, index) => (
                          index < 5 && (
                            <div
                              key={item.id}
                              className="similar-zipcode"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setFilterData((fliterData) => ({
                                  ...fliterData,
                                  zipCode: item.name,
                                }));
                                similarZipcodesearch(item.name);
                                handleGetLocation(item.name);
                              }}
                            >
                              {item.name} <span>{item.country}, {item.state}</span>
                            </div>
                          )
                        ))}

                      {zctaSearchResults && zctaSearchResults.length > constants.STATUS_0 && zctaSearchResults.map((item, index) => (
                        index < 4 && (
                          <div onClick={() => {
                            setFilterData((fliterData) => ({
                              ...fliterData,
                              zipCode: item.name,
                            }));
                            similarZipcodesearch(item.name);
                            handleGetLocation(item.name);
                          }
                          }
                            key={item.id} className="similar-zipcode" style={{ cursor: 'pointer' }} >
                            {item.name} <span>{item.country}, {item.state}</span>
                          </div>
                        )
                      ))}
                    </Col>
                  </Row>
                </>
              }

              {!zCTALoad && !zctaData && !zctaSearchResults ? <div className="text-center mt-5">{alerts.NO_ZCTA_AVAILABLE}</div> : ""}

              {!zctaData ? "" :
                <>
                  <Row className="color-box">
                    <Col className="orange">
                      <div>
                        Per Capita Income
                        <h6>${zctaData?.cityInfo[0]?.pc_income > constants.STATUS_0 ? currencyFormat(zctaData?.cityInfo[0]?.pc_income) : constants.STATUS_0}</h6>
                      </div>
                    </Col>
                    <Col className="blue">
                      <div>
                        Total Population
                        <h6>{zctaData?.cityInfo[0]?.totalpopulation}</h6>
                      </div>
                    </Col>
                    <Col className="green">
                      <div>
                        Population Density
                        <h6>{zctaData?.cityInfo[0]?.populationdensity}</h6>
                      </div>
                    </Col>
                    <Col className="redbg">
                      <div>
                        Family Poverty
                        <h6>{zctaData?.cityInfo[0]?.familyproverty}%</h6>
                      </div>
                    </Col>
                    <Col className="yellow">
                      <div>
                        Unemployment
                        <h6>{zctaData?.cityInfo[0]?.unemployment}%</h6>
                      </div>
                    </Col>
                    <Col className="pink">
                      <div>
                        Median Household Income
                        <h6>${zctaData?.cityInfo[0]?.medianhouseholdincome > constants.STATUS_0 ? currencyFormat(zctaData?.cityInfo[0]?.medianhouseholdincome) : constants.STATUS_0}</h6>
                      </div>
                    </Col>
                  </Row>
                </>
              }
              <Row className="mt-5">
                {!zctaData ? "" :
                  <>
                    {((pointIntrestData.airports.length !== constants.STATUS_0) || (pointIntrestData?.schools?.length !== constants.STATUS_0) || (pointIntrestData?.trains?.length !== constants.STATUS_0)) &&
                      <Col xl={4} md={6}>
                        <div className="analysis-map point-interests">
                          <h3 className="text-center mb-3">Point of Interest</h3>
                          {pointIntrestData?.schools[0] && <p className="college d-flex align-items-center">{pointIntrestData?.schools && pointIntrestData?.schools[0]?.Institution_Name} <br />{pointIntrestData?.schools && pointIntrestData?.schools[0]?.State}, {pointIntrestData?.schools && pointIntrestData?.schools[0]?.ZIP_code}</p>}
                          {pointIntrestData?.trains[0] && <p className="train d-flex align-items-center">{pointIntrestData?.trains && pointIntrestData?.trains[0] && `${pointIntrestData?.trains[0]?.Station},${pointIntrestData?.trains[0]?.City},${pointIntrestData?.trains[0]?.State},${pointIntrestData?.trains[0]?.ZIP_code}`}</p>}
                          {pointIntrestData?.airports[0] && <p className="airport d-flex align-items-center">{pointIntrestData?.airports && pointIntrestData?.airports[0] && `${pointIntrestData?.airports[0]?.Airport},${pointIntrestData?.airports[0]?.City},${pointIntrestData?.airports[0]?.State},${pointIntrestData?.airports[0]?.ZIP_code}`} </p>}
                        </div>
                      </Col>
                    }
                    <Col xl={4} md={6}>
                      <div className="analysis-map">
                        <h3 className="text-center">Median Household(Age)</h3>
                        <BarChart
                          value={zctaData?.medianHouseHoldIncomeByAge[0] ? Object.values((({ name, ...rest }) => rest)(zctaData?.medianHouseHoldIncomeByAge[0])) : []}
                          label={MedianLabel}
                        />
                      </div>
                    </Col>
                    <Col xl={4} md={6}>
                      <div className="analysis-map">
                        <h3 className="text-center">Race and Ethnicity</h3>
                        <BarChart
                          value={zctaData?.houseHoldIncomeDistribution[0] ? Object.values((({ total_population, hispanic, ...rest }) => rest)(zctaData?.raceAndEthinicity[0])) : []}
                          label={RaceLabel}
                        />
                      </div>
                    </Col>
                    <Col xl={4} md={6}>
                      <div className="analysis-map">
                        <h3 className="text-center">Age and Sex</h3>
                        <BarChart
                          value={zctaMaleFemale?.male ? Object.values(zctaMaleFemale?.male) : []}
                          value2={zctaMaleFemale?.female ? Object.values(zctaMaleFemale?.female) : []}
                          type={constants.STATUS_0}
                          label={AgeLabel}
                        />
                        <div className="legends d-flex justify-content-center">
                          <div className="legend-item">
                            <span className="male"></span>
                            <label>Male</label>
                          </div>
                          <div className="legend-item">
                            <span className="female"></span>
                            <label>Female</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} md={6}>
                      <div className="analysis-map">
                        <h3 className="text-center">Households</h3>
                        <BarChart
                          value={zctaData?.houseHolds[0] ? Object.values((({ name, ...rest }) => rest)(zctaData?.houseHolds[0])) : []}
                          label={HouseholdLabel}
                        />
                      </div>
                    </Col>
                    <Col xl={4} md={6}>
                      <div className="analysis-map">
                        <h3 className="text-center">Households Income Distribution</h3>
                        <BarChart
                          value={zctaData?.houseHoldIncomeDistribution[0] ? Object.values((({ name, hhi_total_hh, ...rest }) => rest)(zctaData?.houseHoldIncomeDistribution[0])) : []}
                          label={HouseholdIncomeLabel}
                        />
                      </div>
                    </Col>
                  </>
                }
              </Row>
              <div className="accordion-wrap">
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span>Trends on Offers vs Sales for Last 3 Months</span>
                      <Button variant="primary">View Details<ArrowAccordion /></Button>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion-action d-flex">
                        <CustSelect
                          defaultValue={categories.length > 0 ? { value: categories[0].value, label: categories[0].label } : { value: '', label: '' }}
                          label={Strings.AddProduct.Label1}
                          options={categories}
                          value={categoryValue}
                          placeholder={Strings.Admin.SubCategory.Modal.Placeholder}
                          onChange={(e) => categoriesChange(e)}
                        />
                      </div>
                      {subcategoryNames.length > STATUS_0 ? <div className="graph-wrap">
                        <BarChartVertical
                          value={offers ? Object.values(offers) : []}
                          value2={sales ? Object.values(sales) : []}
                          type={constants.STATUS_0}
                          label={subcategoryNames}
                          indexAxis={'x'}
                        />
                        <div className="legends d-flex justify-content-center">
                          <div className="legend-item">
                            <span className="male"></span>
                            <label>Offers</label>
                          </div>
                          <div className="legend-item">
                            <span className="female"></span>
                            <label>Sales</label>
                          </div>
                        </div>
                      </div> : " No Data Available"}
                    </Accordion.Body>
                  </Accordion.Item>
                  {fliterData.zipCode ?  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span>Daily Trends</span>
                      <Button variant="primary" onClick={() => { setWeekTimeStartDate(new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000)); setWeekTimeEndDate(currentDate); }}>View Details<ArrowAccordion /></Button>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion-action d-flex">
                        <div className="date-picker">
                          <DatePicker
                            selected={weekTimeStartDate}
                            onChange={handleWeekTimeDataChange}
                            startDate={weekTimeStartDate}
                            endDate={weekTimeEndDate}
                            selectsRange={false}
                            maxDate={new Date()} // Restrict selection to current date and past dates
                            customInput={<DatePickerCustomInput className="example-custom-input" />}
                          />
                        </div>
                        <CustSelect
                          defaultValue={categories.length > 0 ? { value: categories[0].value, label: categories[0].label } : { value: '', label: '' }}
                          label={Strings.AddProduct.Label1}
                          options={categories}
                          value={categoryValueHeatmapChart}
                          placeholder={Strings.Admin.SubCategory.Modal.Placeholder}
                          onChange={(e) => categoriesChangeHeatmapChart(e)}
                        />
                      </div>
                      <div className="graph-wrap">
                        <HeatmapChart
                          data={heatmapChartData}
                          minValue={minPriceTimevsWeekDaySales}
                          avgValue={avgPriceTimevsWeekDaySales}
                          maxValue={maxPriceTimevsWeekDaySales}
                          weekTimeStartDate={weekTimeStartDate}
                        />
                      </div>
                      <div className="accordion-bottom d-flex">
                        <div className="weeklinks">
                          <Link className="previous" to="" onClick={handleButtonClickPrevious}>Previous week</Link>
                          {showNextWeekButton ? <Link className="next" to="" onClick={handleButtonClickNext}>Next week</Link> : ""}
                        </div>
                        <div className="legend-wrap">
                          <div className="legend w-100"></div>
                          <div className="legend-dtls d-flex justify-content-between">
                            <span>Low</span>
                            <span>High</span>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  : ""}
                </Accordion>
              </div> 
            </Card.Body>
          </Card>
        </Col>
      </Col>
      <Col>
      </Col>
    </InnerLayout>
  );
};

export default HeatMap;